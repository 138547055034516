import React from 'react'
import PropTypes from 'prop-types';
import { Container, Grid, Divider, Box, Typography} from "@material-ui/core";
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage';
// import { StaticImage } from "gatsby-plugin-image";
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';
import { StaticImage } from "gatsby-plugin-image";

class NewsSectionHomeTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <Element name="news-section">
        <section className="news">
          <ScrollAnimation animateIn="fadeIn">
            <Container maxWidth="md">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className="sectionHeaderCenter">
                  <h2>{this.props.heading}</h2>
                  <Divider />
                </Grid>
              </Grid>
              {posts && (
              <>
                <Grid container spacing={2} style={{marginTop:"20px"}}>
                  {posts.slice(0,2).map(({ node: post }) => (
                  <>
                    <Grid item xs={12} sm={6} className="news-item" key={post.id}>
                      <Link to={post.fields.slug}>
                        {post.frontmatter.featuredimage ? (
                            <PreviewCompatibleImage
                              imageInfo={{
                                image: post.frontmatter.featuredimage,
                                alt: `Featured image thumbnail for post ${post.frontmatter.title}`,
                                
                              }}
                            />
                        ) : 
                          <StaticImage src="../img/image-placeholder.jpg" alt=""  placeholder="blurred"/>
                        }
                        <Box className="newsDetailsContainer">
                          <h3 className="newsTitle">
                            {post.frontmatter.title}
                          </h3>
                          <div className="newsExcerpt">
                            {post.excerpt}
                          </div>
                          <Typography className="link">
                            Read More
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                  </>
                ))}
                </Grid>
                {this.props.showViewAll && (
                  <Grid container style={{marginTop:'18px'}}>
                    <Grid item xs={12} align="center">
                      <Link to="/news" >
                        <button className="primaryButton">
                          View All
                        </button>
                      </Link>
                    </Grid>
                  </Grid>
                )}
                </>
                )}
                {!posts && (
                  <Grid container spacing={2} style={{marginTop:"20px"}}>
                    <Grid item xs={12}>
                      <Typography className='bodyText' align="center">
                        There are no news items at present.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
            </Container>
          </ScrollAnimation>
        </section>
      </Element>
    )
  }
}

NewsSectionHome.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function NewsSectionHome(props) {

  const {
    heading,
    showViewAll
  } = props;


  return (
    <StaticQuery
      query={graphql`
        query BlogRollHomeQuery {
          allMarkdownRemark(
            limit: 2
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "news-post" }, active: { eq: true } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  description
                  templateKey
                  date(formatString: "MMMM DD YYYY")
                  eventdate(formatString: "MMMM DD YYYY")
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <NewsSectionHomeTemplate heading={heading} showViewAll={showViewAll} data={data} count={count} />}
    />
  );
}
