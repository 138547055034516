import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Divider } from "@material-ui/core";
import "./layout.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';
import { StaticImage } from "gatsby-plugin-image";

export default function HMSection(props){

  return (
    <Element name="hm-section">
      <section className="altSectionTwo hm noPadding">
        <ScrollAnimation animateIn="fadeIn">
          <Container maxWidth="md">
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={6} className="hmText">
                <h2>
                  His Majesty<br/>King Charles III
                </h2>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="hmImage">
                <StaticImage src="../img/hm2.jpg" alt=""  placeholder="blurred"/>
              </Grid>
            </Grid>
          </Container>
        </ScrollAnimation>
      </section>
    </Element>
  );
};

HMSection.propTypes = {
props: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

