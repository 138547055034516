import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Hidden, List, ListItem, IconButton, Box } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { scroller } from 'react-scroll';

export default function FixedNavBarHomeDropdown() {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    if(!mobileNavOpen){
      document.body.classList.remove('mobileNavOpen');
    }
  })

  const openMobileNav = () => {
    setMobileNavOpen(true);
    document.body.classList.add('mobileNavOpen');
  }

  const closeMobileNav = () => {
    setMobileNavOpen(false);
    document.body.classList.remove('mobileNavOpen');
  }

  // Scrolling to relevant sections
  const onClickScrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };

  // About Sub Nav - Desktop
  const [ openAbout, setOpenAbout ] = useState();
  const handleOpenAbout = () => {
    if(!openAbout){
        setOpenAbout(true)
    }
    else{
        setOpenAbout(false);
    }
  }

  return (
    <nav className="fixed-navbar-home">
      <Hidden smDown>
        <List className="dt-container">
          <ListItem className="fixed-navbar-item" disableGutters onMouseEnter={() => handleOpenAbout()} onMouseLeave={() => handleOpenAbout()}>
            The High Sheriff
            {openAbout && <div className="sub-nav">
              <List>
                <ListItem disableGutters onClick={() => onClickScrollToSection("about-section")}>
                  About
                </ListItem>
                <ListItem disableGutters onClick={() => onClickScrollToSection("role-section")}>
                  The Role
                </ListItem>
                <ListItem disableGutters onClick={() => onClickScrollToSection("protocol-section")}>
                  Protocol
                </ListItem>
              </List>
            </div>}
          </ListItem>
          <ListItem className="fixed-navbar-item" disableGutters>
            <Link to="/history" className="fixed-navbar-item-link">
              History
            </Link>
          </ListItem>
          <ListItem className="fixed-navbar-item" disableGutters>
            <Link to="/pasthighsheriffs" className="fixed-navbar-item-link">
              Past High Sheriffs
            </Link>
          </ListItem>
          <ListItem className="fixed-navbar-item" disableGutters>
            <Link to="/countydown" className="fixed-navbar-item-link">
              Co Down
            </Link>
          </ListItem>
          <ListItem className="fixed-navbar-item" disableGutters onClick={() => onClickScrollToSection("news-section")}>
            News
          </ListItem>
        </List>
      </Hidden>
      <Hidden mdUp>
        <Box className="mobileNavIconWrapper">
          {/* if mobile menu open */}
          <IconButton aria-label="" onClick={mobileNavOpen === true ? closeMobileNav : openMobileNav}>
            {mobileNavOpen ? (
              <CloseIcon fontSize="large" />
              ) : <MenuIcon fontSize="large" /> }
          </IconButton> 
        </Box>
      </Hidden>
      {mobileNavOpen && 

        // The High Sheriff
        // History
        // Past High Sheriffs
        // Co Down
        // News
        // Contact

        <div className="fixed-navbar-mobile-list-container">
          <List className="fixed-navbar-mobile-list">
            <ListItem className="fixed-navbar-mobile-item" disableGutters>
              <Link to="/currenthighsheriff" className="fixed-navbar-mobile-item-link">
                The High Sheriff
              </Link>
            </ListItem>
            <ListItem className="fixed-navbar-mobile-item" disableGutters>
              <Link to="/history" className="fixed-navbar-mobile-item-link">
                  History
              </Link>
            </ListItem>
            <ListItem className="fixed-navbar-mobile-item" disableGutters>
              <Link to="/pasthighsheriffs" className="fixed-navbar-mobile-item-link">
                Past High Sheriffs
              </Link>
            </ListItem>
            <ListItem className="fixed-navbar-mobile-item" disableGutters>
              <Link to="/countydown" className="fixed-navbar-mobile-item-link">
                Co Down
              </Link>
            </ListItem>
            <ListItem className="fixed-navbar-mobile-item" disableGutters>
              <Link to="/news" className="fixed-navbar-mobile-item-link">
                News
              </Link>
            </ListItem>
          </List>
        </div>
      }
    </nav>
    );
  };