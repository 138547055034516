import React from "react";
import "./layout.css"
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import { Typography, Box } from '@material-ui/core';
import "./layout.css";
import "./custom.css"
import BackgroundImage from 'gatsby-background-image';
import { Element } from 'react-scroll';
import FixedNavBarHomeDropdown from "./FixedNavbarHomeDropdown";

export default function FullWidthImage() {

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "landing-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid
  
  return (
    <React.Fragment>
      <Element name="header">
        <div class="header">
          <BackgroundImage
            Tag="div"
            className="background"
            fluid={imageData}
          >
            <div className="backgroundShadow">
              <FixedNavBarHomeDropdown />
              <Box className="content">   
                <Box className="logoWrapper">
                  <StaticImage src="../img/hscd_logo_v2.png" alt="" className="logo" placeholder="blurred" style={{width:"86%", maxWidth:"600px", height:"auto"}}/>
                </Box>
              </Box>
              <Box className="navigation">
                <Typography>
                  Scroll Down
                </Typography>
              </Box>
            </div>
          </BackgroundImage>
        </div>
      </Element>
    </React.Fragment>
  );
}