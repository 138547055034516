import React from "react";
import PropTypes from "prop-types";
import { Container, Grid } from "@material-ui/core";
import "./layout.css"
// import {getImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';
import { Link } from "gatsby";

export default function LinksSection(props){

// const converter = new showdown.Converter();

  // const {
  //   heading,
  //   description,
  //   image
  // } = props;

  // const hostImage = getImage(image)

  return (
    <Element name="links-section">
      <section className="links noPadding">
        <ScrollAnimation animateIn="fadeIn">
          <Container maxWidth="md">
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4}>
                <Link to="/history">
                  <button className="linkButton">
                    History
                  </button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/pasthighsheriffs">
                  <button className="linkButton">
                    Past High Sheriffs
                  </button>    
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link to="/countydown">
                  <button className="linkButton">
                    County Down
                  </button>    
                </Link>
              </Grid>
            </Grid>
          </Container>
        </ScrollAnimation>
      </section>
    </Element>
  );
};

LinksSection.propTypes = {
props: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

